import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, Link } from "react-router-dom";

import navbarIcon from "../../../assets/bullhorn.png";
import NavButton from "../NavButton/NavButton";

import styles from "./Header.module.css";
import { getSubdomain } from "../../../utils/GetSubdomain";
import {
  SetLocalStorage,
  CleanLocalStorage,
} from "../../../utils/StorageHandler";

const API_URL = process.env.REACT_APP_API_URL;

const Header = () => {
  const { loginWithPopup, logout, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  const navigate = useNavigate();

  const initializeRequest = async (accessToken) => {
    try {
      await fetch(`${API_URL}/orgs/${getSubdomain(window)}/self/initialize`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  };

  const loginHandler = async () => {
    try {
      await loginWithPopup();
      const response = await getAccessTokenSilently();
      initializeRequest(response);
      SetLocalStorage(response);
      navigate("/main");
    } catch (error) {
      console.warn(error);
    }
  };

  const logoutHandler = () => {
    logout({ returnTo: window.location.origin });
    CleanLocalStorage("token");
  };

  return (
    <nav className={styles.navbarContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.iconContainer}>
          <img src={navbarIcon} alt="icon" width="100%" />
        </div>
        <div className={styles.headingContainer}>
          <Link className={styles.headerLink} to="/">
            TRUTH CHOIR
          </Link>
        </div>
      </div>
      {!isAuthenticated && (
        <div className={styles.rightContainer}>
          <NavButton onClick={loginHandler}>Log in</NavButton>
        </div>
      )}
      {isAuthenticated && (
        <div className={styles.rightContainer}>
          <NavButton onClick={logoutHandler}>Log out</NavButton>
        </div>
      )}
    </nav>
  );
};

export default Header;
