/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Container } from "@mui/material";
import styles from "./PrivacyPage.module.css";

import Header from "../../UI/Header/Header";
import Footer from "../../UI/Footer/Footer";
const PrivacyPage = () => (
  <div className={styles.PrivacyPage}>
    <Header />
    <Container sx={{ p: 3, m: 1 }}>
      <h1>Truth Choir: Privacy Policy</h1>
      <Container sx={{ py: 2, px: 0 }}>
        Truth Choir is a site owned by Sterns Tech LLC that allows volunteers to
        find social media content that matches their values so that they can
        engage and boost that content.
      </Container>
      <Container sx={{ py: 2, px: 0 }}>
        This website, truthchoir.org (the "Site") is operated by Sterns Tech
        LLC. ("We" or "Us" or "Truth Choir"). We strive to protect your privacy
        and treat your privacy as we do our own. We wrote this privacy policy to
        let you know how we treat your personal information – and to show you
        all the ways we work to keep it safe and private.
      </Container>
      <Container sx={{ py: 2, px: 0 }}>
        Truth Choir will send you updates by email. You can unsubscribe at any
        time via an unsubscribe link in the email.
      </Container>
      <Container sx={{ py: 2, px: 0 }}>
        We do not share the information you've given us with unaffiliated groups
        without your explicit permission.
      </Container>
      <Container sx={{ py: 2, px: 0 }}>
        Each time you visit this Site or provide us with information through the
        Site, by doing so you are accepting practices described in the privacy
        policy in effect at that time. You are also accepting our specific rules
        or terms of use which may be posted on specific sections of the Site.
      </Container>
      <h2>Children</h2>
      <Container sx={{ py: 2, px: 0 }}>
        Truth Choir does not solicit personally identifying information from
        children. Visitors who are under 13 years of age should not use the
        site.
      </Container>
      <h2>Advertising</h2>
      <Container sx={{ py: 2, px: 0 }}>
        We may place online advertising with third-party vendors, including
        Google, which will be shown on other sites on the internet. In some
        cases, those third-party vendors may decide which ads to show you based
        on your prior visits to the Site. At no time will you be personally
        identified to those third-party vendors, nor will any of the information
        you share with us be shared with those third-party vendors. If you
        prefer to opt out of the use of these third-party cookies on the Site,
        you can do so by visiting the Network Advertising Initiative{" "}
        <a href="https://optout.networkadvertising.org/?c=1">opt out page</a>.
      </Container>
      <h2>Data Tracking</h2>
      <Container sx={{ py: 2, px: 0 }}>
        In order to better serve our members, we use cookies and periodically
        analyze web logs. We may also use third-party services such as Google
        Analytics. This helps us understand traffic patterns and know if there
        are problems with our website. We may also use embedded images in emails
        to track open rates for our mailings, so that we can tell which mailings
        appeal most to Truth Choir members.
      </Container>
      <h2>Data Collection</h2>
      <Container sx={{ py: 2, px: 0 }}>
        In order to register as a volunteer or user of the Site, we ask for and
        collect your email address
      </Container>
      <h2>Data Deletion</h2>
      <Container sx={{ py: 2, px: 0 }}>
        To request deletion of your personal data from our system, you may send
        an email to sternstechllc@gmail.com. In your message, please indicate
        the email address of the account for which you are requesting the
        deletion.
      </Container>
      <h2>Links to Other Websites</h2>
      <Container sx={{ py: 2, px: 0 }}>
        Truth Choir may provide links to third party websites, and other
        websites may provide links to the Site. Third party websites operate
        according to their own terms of use and privacy policies. Truth Choir
        has no control over such third party websites, and by using this Site,
        you acknowledge and agree that Truth Choir is not responsible for the
        availability of such third party sites, and does not endorse and is not
        responsible or liable for any content, advertising, products or other
        materials on or available from such sites. You further acknowledge and
        agree that Truth Choir will not be responsible or liable, directly or
        indirectly, for any damage or loss caused or alleged to be caused by or
        in connection with use of or reliance on any content, advertising,
        products, or other materials on or available from such sites.
      </Container>
      <h2>Changes to the Privacy Policy</h2>
      <Container sx={{ py: 2, px: 0 }}>
        Truth Choir may revise or amend this policy at any time at our sole
        discretion. If we make any substantive changes in the way we collect or
        use personally identifying information provided by users, we will post a
        prominent announcement on our website home page and post the changes on
        this page. We will never change our policies and practices to make them
        less protective of user information collected prior to the effective
        date of any changes without the consent of affected users. We encourage
        users to visit this page periodically to review our current policy.
      </Container>
      <h2>Contact</h2>
      <Container sx={{ py: 2, px: 0 }}>
        If you have any questions about this privacy statement, your personal
        information, or the practices of this Site, you can contact us at
        sternstechllc@gmail.com
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        This privacy policy applies to transactions and activities in which you
        engage and data gathered only on the Site. Please review this privacy
        policy periodically because we modify it from time to time. This privacy
        policy was last modified on October 9, 2022.
      </Container>
    </Container>
    <Footer />
  </div>
);

export default PrivacyPage;
