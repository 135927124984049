import React from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";

import styles from "./StatInfoContainer.module.css";

const StatInfoContainer = ({ stats }) => {
  return (
    <div className={styles.statInfoContainer}>
      <div className={styles.infoBlockContainer}>
        <span className={styles.infoCount}>
          <CountUp end={stats.organizationsCount} duration={1} />
        </span>
        <p className={styles.infoDescription}>2022 Campaigns</p>
      </div>
      <div className={styles.infoBlockContainer}>
        <span className={styles.infoCount}>
          <CountUp end={stats.actionsCount} duration={1} />
        </span>
        <p className={styles.infoDescription}>Messages to Amplify</p>
      </div>
    </div>
  );
};

StatInfoContainer.propTypes = {
  stats: PropTypes.shape({
    actionsCount: PropTypes.number,
    organizationsCount: PropTypes.number,
  }),
};

export default StatInfoContainer;
