/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Container } from "@mui/material";
import styles from "./TermsPage.module.css";

import Header from "../../UI/Header/Header";
import Footer from "../../UI/Footer/Footer";

const TermsPage = () => (
  <div className={styles.TermsPage}>
    <Header />
    <Container sx={{ p: 3, m: 1 }}>
      <h1>Truth Choir: Terms of Use</h1>
      <br />
      <h2>Ownership of Site; Agreement to Terms of Use</h2>
      <Container sx={{ py: 2, px: 0 }}>
        These Terms and Conditions of Use (the "Terms of Use") apply to the
        Truth Choir web site located at https://truthchoir.org, and all
        associated sites linked to https://truthchoir.org by Truth Choir and its
        subsidiaries and affiliates (collectively, the "Site"). The Site is the
        property of Truth Choir. ("Truth Choir") and its licensors.{" "}
        <strong>
          BY USING THE SITE, YOU AGREE TO THESE TERMS OF USE; IF YOU DO NOT
          AGREE, DO NOT USE THE SITE.
        </strong>
      </Container>
      <Container sx={{ py: 2, px: 0 }}>
        Truth Choir reserves the right, at its sole discretion, to change,
        modify, add or remove portions of these Terms of Use, at any time. It is
        your responsibility to check these Terms of Use periodically for
        changes. Your continued use of the Site following the posting of changes
        will mean that you accept and agree to the changes. As long as you
        comply with these Terms of Use, Truth Choir grants you a personal,
        non-exclusive, non-transferable, limited privilege to enter and use the
        Site.
      </Container>

      <h2>Content</h2>
      <Container sx={{ py: 2, px: 0 }}>
        All text, graphics, user interfaces, visual interfaces, photographs,
        trademarks, logos, sounds, music, artwork and computer code
        (collectively, "Content"), including but not limited to the design,
        structure, selection, coordination, expression, "look and feel" and
        arrangement of such Content, contained on the Site is owned, controlled
        or licensed by or to Truth Choir, and is protected by trade dress,
        copyright, patent and trademark laws, and various other intellectual
        property rights and unfair competition laws.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        Except as expressly provided in these Terms of Use, no part of the Site
        and no Content may be copied, reproduced, republished, uploaded, posted,
        publicly displayed, encoded, translated, transmitted or distributed in
        any way (including "mirroring") to any other computer, server, Web site
        or other medium for publication or distribution or for any commercial
        enterprise, without Truth Choir’s express prior written consent.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        You may use information on Truth Choir products and services (such as
        data sheets, knowledge base articles, and similar materials) purposely
        made available by Truth Choir for downloading from the Site, provided
        that you (1) not remove any proprietary notice language in all copies of
        such documents, (2) use such information only for your personal,
        non-commercial informational purpose and do not copy or post such
        information on any networked computer or broadcast it in any media, (3)
        make no modifications to any such information, and (4) not make any
        additional representations or warranties relating to such documents.
      </Container>

      <h2>Your Use of the Site</h2>
      <Container sx={{ py: 2, px: 0 }}>
        You may not use any "deep-link", "page-scrape", "robot", "spider" or
        other automatic device, program, algorithm or methodology, or any
        similar or equivalent manual process, to access, acquire, copy or
        monitor any portion of the Site or any Content, or in any way reproduce
        or circumvent the navigational structure or presentation of the Site or
        any Content, to obtain or attempt to obtain any materials, documents or
        information through any means not purposely made available through the
        Site. Truth Choir reserves the right to bar any such activity.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        You may not attempt to gain unauthorized access to any portion or
        feature of the Site, or any other systems or networks connected to the
        Site or to any Truth Choir server, or to any of the services offered on
        or through the Site, by hacking, using unauthorized codes, password
        "mining" or any other illegitimate means.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        You may not probe, scan or test the vulnerability of the Site or any
        network connected to the Site, nor breach the security or authentication
        measures on the Site or any network connected to the Site. You may not
        reverse look-up, trace or seek to trace any information on any other
        user of or visitor to the Site, or any other customer of Truth Choir,
        including any Truth Choir account not owned by you, to its source, or
        exploit the Site or any service or information made available or offered
        by or through the Site, in any way where the purpose is to reveal any
        information, including but not limited to personal identification or
        information, other than your own information, as provided for by the
        Site.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        You agree that you will not take any action that imposes an unreasonable
        or disproportionately large load on the infrastructure of the Site or
        Truth Choir’s systems or networks, or any systems or networks connected
        to the Site or to Truth Choir.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        You agree not to use any device, software or routine to interfere or
        attempt to interfere with the proper working of the Site or any
        transaction being conducted on the Site, or with any other person’s use
        of the Site.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        You may not forge headers or otherwise manipulate identifiers in order
        to disguise the origin of any message or transmittal you send to Truth
        Choir on or through the Site or any service offered on or through the
        Site. You may not pretend that you are, or that you represent, someone
        else, or impersonate any other individual or entity.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        You may not use the Site or any Content for any purpose that is unlawful
        or prohibited by these Terms of Use, or to solicit the performance of
        any illegal activity or other activity which infringes the rights of
        Truth Choir or others. If you are not a US citizen or permanent
        resident, you may not use the Site or any Content for any purpose in
        violation of the Federal Election Campaign Act’s prohibition on foreign
        national contributions and expenditures.
      </Container>

      <h2>Accounts, Passwords and Security</h2>
      <Container sx={{ py: 2, px: 0 }}>
        Certain features or services offered on or through the Site may require
        you to open an account (including setting up a Truth Choir ID and
        password). You are entirely responsible for maintaining the
        confidentiality of the information you hold for your account, including
        your password, and for any and all activity that occurs under your
        account as a result of your failing to keep this information secure and
        confidential. You agree to notify Truth Choir immediately of any
        unauthorized use of your account or password, or any other breach of
        security. You may be held liable for losses incurred by Truth Choir or
        any other user of or visitor to the Site due to someone else using your
        Truth Choir ID, password or account as a result of your failing to keep
        your account information secure and confidential.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        You may not use anyone else’s Truth Choir ID, password or account at any
        time without the express permission and consent of the holder of that
        Truth Choir ID, password or account. Truth Choir cannot and will not be
        liable for any loss or damage arising from your failure to comply with
        these obligations.
      </Container>

      <h2>Privacy</h2>
      <Container sx={{ py: 2, px: 0 }}>
        Truth Choir’s Privacy Policy applies to use of this Site, and its terms
        are made a part of these Terms of Use by this reference. To view Truth
        Choir’s Privacy Policy, click <a href="/privacy">here</a>. Additionally,
        by using the Site, you acknowledge and agree that Internet transmissions
        are never completely private or secure.
      </Container>

      <h2>Links to Other Sites and to the Truth Choir Site</h2>
      <Container sx={{ py: 2, px: 0 }}>
        This Site may contain links to other independent third-party Web sites
        ("Linked Sites"). These Linked Sites are provided solely as a
        convenience to our visitors. Such Linked Sites are not under Truth
        Choir’s control, and Truth Choir is not responsible for and does not
        endorse the content of such Linked Sites, including any information or
        materials contained on such Linked Sites. You will need to make your own
        independent judgment regarding your interaction with these Linked Sites.
      </Container>

      <h2>Disclaimers</h2>
      <Container sx={{ py: 2, px: 0 }}>
        Truth Choir DOES NOT PROMISE THAT THE SITE OR ANY CONTENT, SERVICE OR
        FEATURE OF THE SITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY
        DEFECTS WILL BE CORRECTED, OR THAT YOUR USE OF THE SITE WILL PROVIDE
        SPECIFIC RESULTS. THE SITE AND ITS CONTENT ARE DELIVERED ON AN "AS-IS"
        AND "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED ON THE SITE IS
        SUBJECT TO CHANGE WITHOUT NOTICE. Truth Choir CANNOT ENSURE THAT ANY
        FILES OR OTHER DATA YOU DOWNLOAD FROM THE SITE WILL BE FREE OF VIRUSES
        OR CONTAMINATION OR DESTRUCTIVE FEATURES. Truth Choir DISCLAIMS ALL
        WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY,
        NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
        Truth Choir DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND
        CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE
        OF THE SITE AND/OR ANY Truth Choir SERVICES. YOU ASSUME TOTAL
        RESPONSIBILITY FOR YOUR USE OF THE SITE AND ANY LINKED SITES. YOUR SOLE
        REMEDY AGAINST Truth Choir FOR DISSATISFACTION WITH THE SITE OR ANY
        CONTENT IS TO STOP USING THE SITE OR ANY SUCH CONTENT. THIS LIMITATION
        OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        The above disclaimer applies to any damages, liability or injuries
        caused by any failure of performance, error, omission, interruption,
        deletion, defect, delay in operation or transmission, computer virus,
        communication line failure, theft or destruction of or unauthorized
        access to, alteration of, or use, whether for breach of contract, tort,
        negligence or any other cause of action.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        Truth Choir reserves the right to do any of the following, at any time,
        without notice: (1) to modify, suspend or terminate operation of or
        access to the Site, or any portion of the Site, for any reason; (2) to
        modify or change the Site, or any portion of the Site, and any
        applicable policies or terms; and (3) to interrupt the operation of the
        Site, or any portion of the Site, as necessary to perform routine or
        non-routine maintenance, error correction, or other changes.
      </Container>

      <h2>Limitation of Liability</h2>
      <Container sx={{ py: 2, px: 0 }}>
        Except where prohibited by law, in no event will Truth Choir be liable
        to you for any indirect, consequential, exemplary, incidental or
        punitive damages, including lost profits, even if Truth Choir has been
        advised of the possibility of such damages.
      </Container>

      <h2>Indemnity</h2>
      <Container sx={{ py: 2, px: 0 }}>
        You agree to indemnify and hold Truth Choir, its officers, directors,
        shareholders, predecessors, successors in interest, employees, agents,
        subsidiaries and affiliates, harmless from any demands, loss, liability,
        claims or expenses (including attorneys’ fees), made against Truth Choir
        by any third party due to or arising out of or in connection with your
        use of the Site.
      </Container>

      <h2>Violation of These Terms of Use</h2>
      <Container sx={{ py: 2, px: 0 }}>
        Truth Choir may disclose any information we have about you (including
        your identity) if we determine that such disclosure is necessary in
        connection with any investigation or complaint regarding your use of the
        Site, or to identify, contact or bring legal action against someone who
        may be causing injury to or interference with (either intentionally or
        unintentionally) Truth Choir’s rights or property, or the rights or
        property of visitors to or users of the Site, including Truth Choir’s
        customers. Truth Choir reserves the right at all times to disclose any
        information that Truth Choir deems necessary to comply with any
        applicable law, regulation, legal process or governmental request. Truth
        Choir also may disclose your information when Truth Choir determines
        that applicable law requires or permits such disclosure, including
        exchanging information with other companies and organizations for fraud
        protection purposes.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        You acknowledge and agree that Truth Choir may preserve any transmittal
        or communication by you with Truth Choir through the Site or any service
        offered on or through the Site, and may also disclose such data if
        required to do so by law or Truth Choir determines that such
        preservation or disclosure is reasonably necessary to (1) comply with
        legal process, (2) enforce these Terms of Use, (3) respond to claims
        that any such data violates the rights of others, or (4) protect the
        rights, property or personal safety of Truth Choir, its employees, users
        of or visitors to the Site, and the public.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        You agree that Truth Choir may, in its sole discretion and without prior
        notice, terminate your access to the Site and/or block your future
        access to the Site if we determine that you have violated these Terms of
        Use or other agreements or guidelines which may be associated with your
        use of the Site. You also agree that any violation by you of these Terms
        of Use may cause irreparable harm to Truth Choir, for which monetary
        damages would be inadequate, and you consent to Truth Choir obtaining
        any injunctive or equitable relief that Truth Choir deems necessary or
        appropriate in such circumstances. These remedies are in addition to any
        other remedies Truth Choir may have at law or in equity.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        You agree that Truth Choir may, in its sole discretion and without prior
        notice, terminate your access to the Site, for cause, which includes
        (but is not limited to) (1) requests by law enforcement or other
        government agencies, (2) a request by you (self-initiated account
        deletions), (3) discontinuance or material modification of the Site or
        any service offered on or through the Site, or (4) unexpected technical
        issues or problems.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        If Truth Choir does take any legal action against you as a result of
        your violation of these Terms of Use, Truth Choir will be entitled to
        recover from you, and you agree to pay, all reasonable attorneys’ fees
        and costs of such action, in addition to any other relief granted to
        Truth Choir. You agree that Truth Choir will not be liable to you or to
        any third party for termination of your access to the Site as a result
        of any violation of these Terms of Use.
      </Container>

      <h2>Governing Law; Dispute Resolution</h2>
      <Container sx={{ py: 2, px: 0 }}>
        You agree that all matters relating to your access to or use of the
        Site, including all disputes, will be governed by the laws of the United
        States and by the laws of the State of California without regard to its
        conflicts of laws provisions. You agree to the personal jurisdiction by
        and venue in the state and federal courts in Alameda, California, and
        waive any objection to such jurisdiction or venue.
      </Container>

      <h2>Miscellaneous</h2>
      <Container sx={{ py: 2, px: 0 }}>
        You may not use or export or re-export any Content or any copy or
        adaptation of such Content, or any product or service offered on the
        Site, in violation of any applicable laws or regulations, including
        without limitation United States export laws and regulations.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        If any of the provisions of these Terms of Use are held by a court or
        other tribunal of competent jurisdiction to be void or unenforceable,
        such provisions shall be limited or eliminated to the minimum extent
        necessary and replaced with a valid provision that best embodies the
        intent of these Terms of Use, so that these Terms of Use shall remain in
        full force and effect. These Terms of Use constitute the entire
        agreement between you and Truth Choir with regard to your use of the
        Site, and any and all other written or oral agreements or understandings
        previously existing between you and Truth Choir with respect to such use
        are hereby superseded and cancelled. Truth Choir’s failure to insist on
        or enforce strict performance of these Terms of Use shall not be
        construed as a waiver by Truth Choir of any provision or any right it
        has to enforce these Terms of Use, nor shall any course of conduct
        between Truth Choir and you or any other party be deemed to modify any
        provision of these Terms of Use. These Terms of Use shall not be
        interpreted or construed to confer any rights or remedies on any third
        parties.
      </Container>

      <h2>Feedback and Information</h2>
      <Container sx={{ py: 2, px: 0 }}>
        Any feedback you provide at this site shall be deemed to be
        non-confidential. Truth Choir shall be free to use such information on
        an unrestricted basis.
      </Container>

      <Container sx={{ py: 2, px: 0 }}>
        The information contained in this web site is subject to change without
        notice.
      </Container>

      <strong>These Terms of Use were last updated on October 8, 2022.</strong>
    </Container>
    <Footer />
  </div>
);
export default TermsPage;
