import React from "react";
/*
import facebookIcon from "../../../assets/social-media-icons/facebook_icon.png";
import twitterIcon from "../../../assets/social-media-icons/twitter_icon.png";
import instagramIcon from "../../../assets/social-media-icons/instagram_icon.png";
import linkedingIcon from "../../../assets/social-media-icons/linkedin_icon.png";
import youtubeIcon from "../../../assets/social-media-icons/youtube_icon.png";
*/
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.leftContainer}>
        <h3>TRUTH CHOIR</h3>
        <ul className={styles.footerLinksContainer}>
          <li className={styles.footerLinksItem}>
            © 2022 · All rights reserved
          </li>
          <li className={styles.footerLinksItem}>
            <a href="mailto:sternstechllc@gmail.com">Contact Us</a>
          </li>
          <li className={styles.footerLinksItem}>
            <a href="/terms">Terms</a> / <a href="/privacy">Privacy</a>
          </li>
        </ul>
      </div>
      {/*
      <div className={styles.rightContainer}>
        <ul className={styles.socialMediaLinksContainer}>
          <li className={styles.socialMediaLinksItem}>
            <a href="#">
              <img src={facebookIcon} alt="facebook-icon" />
            </a>
          </li>
          <li className={styles.socialMediaLinksItem}>
            <a href="#">
              <img src={twitterIcon} alt="twitter-icon" />
            </a>
          </li>
          <li className={styles.socialMediaLinksItem}>
            <a href="#">
              <img src={instagramIcon} alt="instagram-icon" />
            </a>
          </li>
          <li className={styles.socialMediaLinksItem}>
            <a href="#">
              <img src={linkedingIcon} alt="linkedin-icon" />
            </a>
          </li>
          <li className={styles.socialMediaLinksItem}>
            <a href="#">
              <img src={youtubeIcon} alt="youtube-icon" />
            </a>
          </li>
        </ul>
      </div>
      */}
    </footer>
  );
};

export default Footer;
