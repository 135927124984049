import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";

import styles from "./ModalContainer.module.css";

import { getSubdomain } from "../../../utils/GetSubdomain";

const REACT_APP_DEFAULT_SLUG = process.env.REACT_APP_DEFAULT_SLUG;

const ModalTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

ModalTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalContainer = ({
  children,
  title,
  onClose,
  isLoading,
  isOpen,
  onSubmit,
  onReset,
  isFinish,
}) => {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      {isLoading && (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      )}
      <ModalTitle onClose={onClose}>{title}</ModalTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {!isFinish ? (
          <>
            {getSubdomain(window) === REACT_APP_DEFAULT_SLUG && (
              <Button
                type="submit"
                variant="outlined"
                onClick={() => onSubmit("all")}
                className={styles.importAllOrgsButton}
              >
                Import all Campaigns
              </Button>
            )}
            <Button type="submit" variant="contained" onClick={onSubmit}>
              Import
            </Button>
          </>
        ) : (
          <Button type="submit" variant="contained" onClick={onReset}>
            Import More
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ModalContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  isFinish: PropTypes.bool.isRequired,
};

export default ModalContainer;
