import React from "react";

import styles from "./MassMediaContainer.module.css";

import GuardianLogo from "../../../../assets/guardian_logo.png";
import IsdLogo from "../../../../assets/isd_logo.png";
import GfmLogo from "../../../../assets/gfm_logo.png";

const MassMediaContainer = () => {
  return (
    <div className={styles.massMediaContainer}>
      <div className={styles.infoBlockContainer}>
        <div className={styles.imgContainer}>
          <img src={GuardianLogo} alt="" className={styles.img} />
        </div>
        <p className={styles.infoDescription}>
          “21 of the largest far-right pages—including one linked to a
          right-wing terror group—broadcast over 1,000 fake news posts a week to
          1 million followers...”
          <span className={styles.infoSource}>The Guardian</span>
        </p>
      </div>
      <div className={styles.infoBlockContainer}>
        <div className={styles.imgContainer}>
          <img src={IsdLogo} alt="" className={styles.img} />
        </div>
        <p className={styles.infoDescription}>
          “A small number of conservative users routinely outpace their liberal
          rivals and traditional news outlets in driving the online conversation
          — amplifying their impact...”
          <span className={styles.infoSource}>
            Institute for Strategic Dialogue
          </span>
        </p>
      </div>
      <div className={styles.infoBlockContainer}>
        <div className={styles.imgContainer}>
          <img src={GfmLogo} alt="" className={styles.img} />
        </div>
        <p className={styles.infoDescription}>
          “Currently, disinformation warriors are especially active on the
          political right... Tides of disinformation are now coming in faster
          than researchers can document them.”
          <span className={styles.infoSource}>German Marshall Fund</span>
        </p>
      </div>
    </div>
  );
};

export default MassMediaContainer;
