import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";

function RoleCreate() {
  return (
    <Create>
      <SimpleForm rowClick="edit">
        <TextInput source="auth0Id" validate={[required()]} />
        <ReferenceInput label="Role" source="roleSlug" reference="roles">
          <SelectInput
            optionText="name"
            optionValue="id"
            emptyValue={null}
            validate={[required()]}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}

export default RoleCreate;
