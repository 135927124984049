import React from "react";
import PropTypes from "prop-types";

import HomeButton from "../../HomeButton/HomeButton";

import styles from "./MainContainer.module.css";

const MainContainer = ({
  signupHandler,
  isAuthenticated,
  organizationName,
}) => (
  <div className={styles.mainContainerWrap}>
    <div className={styles.mainContainer}>
      <div className={styles.mainContainerContent}>
        {!organizationName && (
          <h1 className={styles.contentTitle}>
            AMPLIFY DEMOCRATIC VOICES <br /> JOIN TRUTH CHOIR
          </h1>
        )}

        {organizationName && organizationName.type === "org" && (
          <h1 className={styles.contentTitleWithOrg}>
            AMPLIFY THE VOICE OF THE <span>{organizationName.title}&nbsp;</span>
            CAMPAIGN AND OTHER DEMOCRATIC VOICES <br /> JOIN TRUTH CHOIR
          </h1>
        )}

        {organizationName && organizationName.type === "state" && (
          <h1 className={styles.contentTitleWithOrg}>
            AMPLIFY VOICES OF DEMOCRATIC CAMPAIGNS IN {}
            <span>{organizationName.title}</span> <br /> JOIN TRUTH CHOIR
          </h1>
        )}
        <h2 className={styles.contentDescription}>
          Amplify issues and candidates you care about on Twitter and Facebook!
        </h2>
        <div className={styles.signupButtonContainer}>
          <HomeButton onClick={signupHandler} variant="yellow">
            {isAuthenticated ? "Get Started!" : "Sign Up"}
          </HomeButton>
        </div>
      </div>
    </div>
  </div>
);

MainContainer.propTypes = {
  signupHandler: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  organizationName: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default MainContainer;
