import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  minLength,
  maxLength,
  AutocompleteInput,
} from "react-admin";

import States from "../../../utils/States";

function OrganizationEdit() {
  const validateName = [required(), minLength(3), maxLength(24)];
  const validateSlug = [required(), minLength(3), maxLength(12)];

  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm rowClick="edit">
        <TextInput source="name" validate={validateName} />
        <TextInput source="slug" validate={validateSlug} />
        <AutocompleteInput
          source="state"
          choices={States}
          optionValue="value"
          optionText="name"
          validate={[required()]}
        />
        <TextInput source="twitterHandle" />
      </SimpleForm>
    </Edit>
  );
}

export default OrganizationEdit;
