export const SetLocalStorage = async (token) => {
  localStorage.setItem("token", token);
};

export const GetLocalStorage = () => {
  const storedToken = localStorage.getItem("token");
  return storedToken;
};

export const CleanLocalStorage = (params) => {
  localStorage.removeItem(params);
};
