import React from "react";
import PropTypes from "prop-types";

import styles from "./HomeButton.module.css";

const HomeButton = ({ children, onClick, variant }) => {
  return (
    <button
      className={
        variant && variant === "yellow"
          ? styles.signupYellowBtn
          : styles.signupBlueBtn
      }
      onClick={onClick}
    >
      {children}
    </button>
  );
};

HomeButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  variant: PropTypes.string,
};

export default HomeButton;
