import React from "react";
import styles from "./Loader.module.css";
import { CircularProgress, Box } from "@mui/material";

const Loader = () => (
  <div className={styles.loaderWrap}>
    <Box className={styles.loaderContainer}>
      <CircularProgress size={80} color="primary" />
    </Box>
  </div>
);

export default Loader;
