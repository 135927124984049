import * as React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";

function OrganizationList() {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="slug" />
        <TextField source="state" />
        <TextField source="twitterHandle" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  );
}

export default OrganizationList;
