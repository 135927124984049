import React from "react";

import styles from "./IssuesInfoContainer.module.css";

const IssuesInfoContainer = () => (
  <div className={styles.issuesInfoContainer}>
    <div className={styles.issuesInfoLeftContainer}>
      <h1 className={styles.issuesDescription}>
        SUPPORT VOICES ON ISSUES YOU CARE ABOUT
      </h1>
    </div>
    <div className={styles.issuesInfoRightContainer}>
      <div className={styles.issuesInfoRightContainerWrap}>
        <ul className={styles.tagList}>
          <li className={styles.tagListItem}>#CLIMATE CHANGE</li>
          <li className={styles.tagListItem}>#WOMENS RIGHTS</li>
          <li className={styles.tagListItem}>#GAY MARRIAGE</li>
          <li className={styles.tagListItem}>#BLM</li>
          <li className={styles.tagListItem}>#ECONOMY</li>
          <li className={styles.tagListItem}>#IMMIGRATION</li>
          <li className={styles.tagListItem}>#DEMOCRACY</li>
          <li className={styles.tagListItem}>#EDUCATION</li>
        </ul>
      </div>
    </div>
  </div>
);

export default IssuesInfoContainer;
