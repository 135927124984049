import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  AutocompleteArrayInput,
  useRecordContext,
} from "react-admin";

import { getSubdomain } from "../../../utils/GetSubdomain";

const REACT_APP_DEFAULT_SLUG = process.env.REACT_APP_DEFAULT_SLUG;

const choices = [
  { id: 1, name: "Twitter" },
  { id: 2, name: "Facebook" },
];

const CustomForm = () => {
  const record = useRecordContext();
  if (!record) return null;
  const tags = record.tags ? record.tags.slice() : [];

  return (
    <SimpleForm rowClick="edit">
      {getSubdomain(window) === REACT_APP_DEFAULT_SLUG && (
        <TextInput source="organizationSlug" />
      )}
      <SelectInput
        source="platform"
        choices={choices}
        optionValue="name"
        validate={[required()]}
      />
      <TextInput source="contentType" validate={[required()]} />
      <TextInput source="contentUrl" validate={[required()]} />
      <AutocompleteArrayInput
        onCreate={(tag) => {
          const newTag = { tag };
          if (!tags.find((tag) => tag.tag == newTag.tag)) {
            tags.push(newTag);
            return newTag;
          }
          return false;
        }}
        inputValue={() => false}
        inputText={() => false}
        inputMode="text"
        source="tags"
        choices={tags}
        optionText={(option) => (option.tag ? option.tag : "")}
        optionValue="tag"
        parse={(value) => value && value.map((v) => ({ tag: v }))}
        format={(value) => value && value.map((v) => v.tag)}
        onKeyDownCapture={(e) => {
          if (e.key === "Enter") {
            e.stopPropagation();
          }
        }}
      />
    </SimpleForm>
  );
};

function ActionEdit() {
  const transform = (data) => ({
    ...data,
    tags: data.tags.filter((tag) => tag.tag),
  });

  return (
    <Edit redirect={false} transform={transform}>
      <CustomForm />
    </Edit>
  );
}

export default ActionEdit;
