import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
} from "react-admin";

function RoleEdit() {
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm rowClick="edit">
        <TextInput source="auth0Id" validate={[required()]} />
        <ReferenceInput label="Role" source="roleSlug" reference="roles">
          <SelectInput
            optionText="name"
            optionValue="id"
            emptyValue={null}
            validate={[required()]}
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}

export default RoleEdit;
