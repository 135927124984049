import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { getSubdomain } from "./utils/GetSubdomain";

import HomePage from "./components/Pages/HomePage/HomePage";
import MainPage from "./components/Pages/MainPage/MainPage";
import AdminPage from "./components/Pages/AdminPage/AdminPage";
import PrivacyPage from "./components/Pages/PrivacyPage/PrivacyPage";
import TermsPage from "./components/Pages/TermsPage/TermsPage";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./components/Pages/Loader/Loader";
import { SetLocalStorage } from "./utils/StorageHandler";
import States from "./utils/States";

const API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_DEFAULT_SLUG = process.env.REACT_APP_DEFAULT_SLUG;
const REACT_APP_HOST = process.env.REACT_APP_HOST;

const App = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [organization, setOrganization] = useState(null);

  const loadToken = async () => {
    try {
      if (!isLoading && isAuthenticated) {
        const token = await getAccessTokenSilently();
        SetLocalStorage(token);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const organizationCheck = (subdomain) =>
    fetch(`${API_URL}/orgs/${subdomain}/check`, {
      method: "GET",
    });

  useEffect(() => {
    const html = document.querySelector("html");

    if (isLoading) {
      html.style.overflow = "hidden";
    } else {
      html.style.overflow = "auto";
    }

    loadToken();
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        const subdomain = getSubdomain(window);

        if (!subdomain || subdomain.length < 2) {
          throw Error();
        }

        const state = States.find(
          (state) => state.value === subdomain.toUpperCase()
        );

        if (subdomain !== REACT_APP_DEFAULT_SLUG) {
          const response = await organizationCheck(subdomain);
          if (!response.ok) {
            throw Error();
          }

          const organization = await response.json();

          if (subdomain.length > 2) {
            setOrganization({ title: organization.name, type: "org" });
          } else {
            setOrganization({ title: state.name, type: "state" });
          }
        }
      } catch (error) {
        window.location.replace(
          `${window.location.protocol}//${REACT_APP_DEFAULT_SLUG}.${REACT_APP_HOST}`
        );
      }
    }
    fetchData();
  }, []);

  return (
    <BrowserRouter>
      {isLoading && <Loader />}
      <Routes>
        <Route path="/" element={<HomePage organization={organization} />} />
        <Route
          path="/main"
          element={<MainPage organization={organization} />}
        />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/admin/*" element={<AdminPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
