import React from "react";
import styles from "./QuestionsContainer.module.css";

const QuestionsContainer = () => {
  return (
    <div className={styles.questionsContainer}>
      <div className={styles.infoBlockContainer}>
        <span className={styles.infoQuestion}>WHY SUPPORT LIBERAL VOICES?</span>
        <p className={styles.infoDescription}>
          The right-wing currently dominates American social media by
          cultivating an echo chamber where right-wing users or paid troll farms
          boost their own content through likes and shares. Truth Choir was
          created to combat this effect by amplifying progressive voices on
          important issues like abortion rights and climate change.
        </p>
      </div>
      <div className={styles.infoBlockContainer}>
        <span className={styles.infoQuestion}>HOW ARE POSTS SELECTED?</span>
        <p className={styles.infoDescription}>
          Featured social media posts from Twitter are taken from the most
          competitive races from across the country. The more users share, like
          and comment on a post the more likely they are to be featured. In the
          future, Truth Choir will also include posts from other social media
          platforms.
        </p>
      </div>
    </div>
  );
};

export default QuestionsContainer;
