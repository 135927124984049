import React from "react";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import Close from "@mui/icons-material/Close";

import { Box, Paper, Tooltip, IconButton } from "@mui/material";

import styles from "./infoContainer.module.css";

const InfoContainer = ({ children, handleClose }) => {
  return (
    <Box className={styles.infoContainer}>
      <Paper className={styles.paperWrap} elevation={4}>
        <div className={styles.iconInfoWrap}>
          <InfoIcon />
        </div>
        <p>{children}</p>
        <div className={styles.iconCloseWrap}>
          <Tooltip title="Close">
            <IconButton onClick={() => handleClose()}>
              <Close />
            </IconButton>
          </Tooltip>
        </div>
      </Paper>
    </Box>
  );
};

InfoContainer.propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func,
};

export default InfoContainer;
