import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

import {
  Container,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Autocomplete,
  TextField,
  Checkbox,
} from "@mui/material";

import styles from "./MainPage.module.css";
import Header from "../../UI/Header/Header";
import Footer from "../../UI/Footer/Footer";
import ActionContainer from "../../UI/ActionContainer/ActionContainer";
import { GetLocalStorage } from "../../../utils/StorageHandler";
import { getSubdomain } from "../../../utils/GetSubdomain";

const API_URL = process.env.REACT_APP_API_URL;

const Form = ({
  platform,
  setPlatform,
  organizations,
  filteredOrganizations,
  setFilteredOrganization,
  getActionByFilter,
  setIsLoading,
}) => {
  const accessToken = GetLocalStorage();
  const handlePlatformChange = async (event) => {
    try {
      setIsLoading(true);
      setPlatform(event.target.value);
      await getActionByFilter(
        accessToken,
        event.target.value,
        filteredOrganizations
      );
      setIsLoading(false);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleOrganizationsChange = async (event, values) => {
    try {
      setIsLoading(true);
      setFilteredOrganization(values);
      await getActionByFilter(accessToken, platform, values);
      setIsLoading(false);
    } catch (error) {
      console.warn(error);
    }
  };
  const addStateToOrg = (org) =>
    org.state ? `${org.state} - ${org.name}` : org.name;

  return (
    <form>
      <div className={styles.formRow}>
        <Typography variant="h6" component="span">
          Select Platform
        </Typography>
        <FormControl style={{ width: "65%" }}>
          <Select
            id="platform-select"
            value={platform}
            onChange={handlePlatformChange}
            defaultValue="All"
            style={{
              fontSize: 14,
            }}
          >
            <MenuItem value="All">All Platforms</MenuItem>
            <MenuItem value="Facebook">Facebook</MenuItem>
            <MenuItem value="Twitter">Twitter</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={styles.formRow}>
        <Typography variant="h6" component="span">
          Filter Campaigns <br /> (default is all)
        </Typography>
        <FormControl style={{ width: "65%" }}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            id="orgs"
            options={organizations}
            getOptionLabel={(option) => addStateToOrg(option)}
            onChange={handleOrganizationsChange}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {addStateToOrg(option)}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Campaigns"
                placeholder="Campaigns"
              />
            )}
          />
        </FormControl>
      </div>
    </form>
  );
};

Form.propTypes = {
  platform: PropTypes.string,
  setPlatform: PropTypes.func,
  organizations: PropTypes.array,
  filteredOrganizations: PropTypes.array,
  setFilteredOrganization: PropTypes.func,
  getActionByFilter: PropTypes.func,
  setIsLoading: PropTypes.func,
};

const MainPage = ({ organization }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const [platform, setPlatform] = useState("All");
  const [actions, setActions] = useState([]);
  const [currentAction, setCurrentAction] = useState();
  const [isActionLoading, setActionLoading] = useState(false);
  const [user, setUser] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [filteredOrganizations, setFilteredOrganization] = useState([]);
  const navigate = useNavigate();
  const subdomain = getSubdomain(window);

  const getActionByFilter = async (
    accessToken,
    platform,
    filteredOrganizations
  ) => {
    const actionsResponse = await getUserActions(
      accessToken,
      platform,
      filteredOrganizations
    );
    const actions = await actionsResponse.json();

    if (actions.length > 0) {
      setCurrentAction(actions[0]);
    } else {
      setCurrentAction(null);
    }

    setActions(actions);
  };

  const getUser = (accessToken) =>
    fetch(`${API_URL}/orgs/${subdomain}/users/self`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

  const getUserActions = (accessToken, platform = "All", organizations = []) =>
    fetch(
      `${API_URL}/orgs/${subdomain}/users/self/actions?${new URLSearchParams([
        ...organizations.map((org) => ["organizations", org.slug]),
        ["platform", platform],
      ])}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

  const getOrganizations = async (accessToken) =>
    await fetch(`${API_URL}/orgs/${subdomain}/organizations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

  const updateStatusAction = async (actionId, status) => {
    try {
      const accessToken = GetLocalStorage();

      await fetch(
        `${API_URL}/orgs/${subdomain}/users/self/actions/${actionId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status: status,
          }),
        }
      );

      if (status === "INTERACTED") {
        const userResponse = await getUser(accessToken);
        const user = await userResponse.json();
        setUser(user);
      }

      const filteredActionsWithoutSkipped = actions.filter(
        (action) => action.id != actionId
      );
      setActions(filteredActionsWithoutSkipped);
      if (filteredActionsWithoutSkipped.length > 0) {
        setCurrentAction(filteredActionsWithoutSkipped[0]);
      } else {
        setCurrentAction(null);
      }

      setActionLoading(false);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const accessToken = GetLocalStorage();

      try {
        if (!isLoading && isAuthenticated) {
          const userResponse = await getUser(accessToken);
          const actionsResponse = await getUserActions(accessToken);
          const organizationsResponse = await getOrganizations(accessToken);
          const user = await userResponse.json();
          const actions = await actionsResponse.json();
          const organizations = await organizationsResponse.json();

          if (actions.length > 0) {
            setCurrentAction(actions[0]);
          }

          setOrganizations(
            organizations
              .map(({ name, slug, state }) => ({ name, slug, state }))
              .filter(({ slug }) => slug != "truth")
          );
          setUser(user);
          setActions(actions);
        } else if (!isLoading && !isAuthenticated) {
          navigate("/");
        }
      } catch (error) {
        console.warn(error);
      }
    }
    fetchData();
  }, [isAuthenticated, isLoading]);

  return (
    <div className={styles.MainPage}>
      <Header />
      <Container className={styles.mainContainer}>
        <div className={styles.mainDesc}>
          <h1>Hi there! Which campaigns do you want to support today?</h1>
          <p>
            Social media posts shown are taken from the most competitive
            campaigns across the country. Help amplify their message by liking,
            sharing and commenting and earn a point per post!
          </p>
        </div>
        <Form
          platform={platform}
          setPlatform={setPlatform}
          organizations={organizations}
          filteredOrganizations={filteredOrganizations}
          setFilteredOrganization={setFilteredOrganization}
          getActionByFilter={getActionByFilter}
          setIsLoading={setActionLoading}
        />
        {user && (
          <div className={styles.ActionContainerWrap}>
            <ActionContainer
              action={currentAction}
              user={user}
              updateStatusAction={updateStatusAction}
              isLoading={isActionLoading}
              setIsLoading={setActionLoading}
              countActions={
                organization &&
                actions.filter((action) => {
                  if (organization.type === "state") {
                    return (
                      action.organization_state === subdomain.toUpperCase()
                    );
                  } else {
                    return action.organization_slug === subdomain;
                  }
                }).length
              }
              countOtherActions={organization && actions.length}
              orgTitle={organization && organization.title}
            />
          </div>
        )}
      </Container>
      <Footer />
    </div>
  );
};

MainPage.propTypes = {
  organization: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default MainPage;
