import React, { useState } from "react";
import PropTypes from "prop-types";
import { FacebookEmbed, TwitterEmbed } from "react-social-media-embed";

import { Card, CardActions, CardContent, Button } from "@mui/material";

import { GetLocalStorage } from "../../../utils/StorageHandler";
import { getSubdomain } from "../../../utils/GetSubdomain";
import styles from "./ActionContainer.module.css";
import InfoContainer from "../infoContainer/infoContainer";
import Loader from "../../Pages/Loader/Loader";

const API_URL = process.env.REACT_APP_API_URL;

const ActionContainer = ({
  action,
  updateStatusAction,
  isLoading,
  setIsLoading,
  user,
  countActions,
  countOtherActions,
  orgTitle,
}) => {
  const { actionInstructionsDismissedAt, points } = user;

  const [infoStatus, setInfoStatus] = useState(actionInstructionsDismissedAt);

  const handleClickSupported = (actionId) => {
    setIsLoading(true);
    updateStatusAction(actionId, "INTERACTED");
  };

  const handleClickSkipped = (actionId) => {
    setIsLoading(true);
    updateStatusAction(actionId, "SKIPPED");
  };

  const handleCloseInfo = async () => {
    try {
      setInfoStatus(true);
      const accessToken = GetLocalStorage();

      await fetch(`${API_URL}/orgs/${getSubdomain(window)}/users/self`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          actionInstructionsDismissedAt: new Date(),
        }),
      });
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Card>
        {!infoStatus && (
          <InfoContainer handleClose={handleCloseInfo}>
            To use Truth Choir, you must have either a Twitter or Facebook
            account, or ideally both. You can like, share, and comment directly
            on the Twitter or Facebook post. To get credit and earn a point,
            select the “I supported this!” button afterwards. Let&apos;s
            amplify!
          </InfoContainer>
        )}
        <CardContent className={styles.cardContent}>
          {action && action.platform === "Facebook" ? (
            <FacebookEmbed
              url={action.content_url}
              key={action.id}
              width="100%"
              className={styles.contentEmbed}
            />
          ) : action && action.platform === "Twitter" ? (
            <TwitterEmbed
              url={action.content_url}
              key={action.id}
              width="100%"
              className={styles.contentEmbed}
            />
          ) : (
            <>No more actions available</>
          )}
        </CardContent>
        <CardActions>
          <div className={styles.cardActionsWrap}>
            <div className={styles.cardActionsInfo}>
              <div className={styles.cardActionsInfoItem}>
                <h4>Your points</h4>
                <span>{points}</span>
              </div>
              {countActions > 0 && (
                <div className={styles.cardActionsInfoItem}>
                  <h4>Remaining Actions in {orgTitle}</h4>
                  <span>{countActions}</span>
                </div>
              )}

              {countActions === 0 && (
                <div className={styles.cardActionsInfoItem}>
                  <h4>No more actions in {orgTitle}</h4>
                  <h4>Showing nationwide actions</h4>
                  <span>{countOtherActions}</span>
                </div>
              )}
            </div>
            <div className={styles.cardActionsButtons}>
              <Button
                variant="contained"
                onClick={() => handleClickSupported(action.id)}
                disabled={!action}
              >
                I supported this!
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleClickSkipped(action.id)}
                disabled={!action}
              >
                Skip this one
              </Button>
            </div>
          </div>
        </CardActions>
      </Card>
    </>
  );
};

ActionContainer.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.number,
    content_type: PropTypes.string,
    content_url: PropTypes.string,
    created_at: PropTypes.string,
    deleted_at: PropTypes.string,
    organization_slug: PropTypes.string,
    platform: PropTypes.string,
    updated_at: PropTypes.string,
  }),
  user: PropTypes.shape({
    id: PropTypes.number,
    points: PropTypes.number,
    introCompletedAt: PropTypes.string,
    actionInstructionsDismissedAt: PropTypes.string,
  }),
  updateStatusAction: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  countActions: PropTypes.number,
  countOtherActions: PropTypes.number,
  orgTitle: PropTypes.string,
};

export default ActionContainer;
