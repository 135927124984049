import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  AutocompleteArrayInput,
} from "react-admin";

import { getSubdomain } from "../../../utils/GetSubdomain";

const REACT_APP_DEFAULT_SLUG = process.env.REACT_APP_DEFAULT_SLUG;

const choices = [
  { id: 1, name: "Twitter" },
  { id: 2, name: "Facebook" },
];

function ActionCreate() {
  const transform = (data) => ({
    ...data,
    tags: data.tags.filter((tag) => tag.tag),
  });
  const tags = [];

  return (
    <Create transform={transform}>
      <SimpleForm rowClick="edit">
        {getSubdomain(window) === REACT_APP_DEFAULT_SLUG && (
          <TextInput source="organizationSlug" />
        )}
        <SelectInput
          source="platform"
          choices={choices}
          optionValue="name"
          validate={[required()]}
        />
        <TextInput source="contentType" validate={[required()]} />
        <TextInput source="contentUrl" validate={[required()]} />
        <AutocompleteArrayInput
          onCreate={(tag) => {
            const newTag = { tag };
            if (!tags.find((tag) => tag.tag == newTag.tag)) {
              tags.push(newTag);
              return newTag;
            }
            return false;
          }}
          inputValue={() => false}
          inputText={() => false}
          source="tags"
          choices={tags}
          optionText={(option) => (option.tag ? option.tag : "")}
          optionValue="tag"
          parse={(value) => value && value.map((v) => ({ tag: v }))}
          format={(value) => value && value.map((v) => v.tag)}
          onKeyDownCapture={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation();
            }
          }}
        />
      </SimpleForm>
    </Create>
  );
}

export default ActionCreate;
