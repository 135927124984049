import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  UrlField,
  ChipField,
  SingleFieldList,
  ReferenceManyField,
  TopToolbar,
  CreateButton,
  Button,
} from "react-admin";

import TwitterIcon from "@mui/icons-material/Twitter";

import TweetsImportContainer from "../TweetsImportContainer/TweetsImportContainer";

const ListActions = ({ openTW }) => {
  return (
    <TopToolbar>
      <CreateButton />
      <Button onClick={() => openTW()} label="Twitter">
        <TwitterIcon />
      </Button>
    </TopToolbar>
  );
};

ListActions.propTypes = {
  openTW: PropTypes.func.isRequired,
};

function ActionList() {
  const [isOpenTW, setIsOpenTW] = useState(false);

  const handleClose = () => {
    setIsOpenTW(false);
  };

  const handleOpenTW = () => {
    setIsOpenTW(true);
  };

  return (
    <>
      {isOpenTW && (
        <TweetsImportContainer open={isOpenTW} onClose={handleClose} />
      )}
      <List actions={<ListActions openTW={handleOpenTW} />} empty={false}>
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="platform" />
          <TextField source="contentType" />
          <TextField source="organizationSlug" label="Organization" />
          <UrlField source="contentUrl" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <ReferenceManyField
            label="Tags"
            reference="action-tags"
            target="actionId"
            linkType={false}
          >
            <SingleFieldList linkType={false}>
              <ChipField source="tag" />
            </SingleFieldList>
          </ReferenceManyField>
        </Datagrid>
      </List>
    </>
  );
}

export default ActionList;
