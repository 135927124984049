import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import BusinessIcon from "@mui/icons-material/Business";
import { useRefresh } from "react-admin";

import {
  FormControl,
  TextField,
  Slider,
  InputAdornment,
  Autocomplete,
} from "@mui/material";

import styles from "./TweetsImportContainer.module.css";
import ModalContainer from "../Modals/ModalContainer";
import { GetLocalStorage } from "../../../utils/StorageHandler";
import { getSubdomain } from "../../../utils/GetSubdomain";

const API_URL = process.env.REACT_APP_API_URL;

const ImportResultContainer = ({ result }) => {
  const { imported, duplicate, error } = result;
  return (
    <div className={styles.resultContainer}>
      <div className={styles.resultContainerRowImported}>
        <span className={styles.resultLabel}>Imported:</span>
        <span className={styles.resultValue}>{imported}</span>
      </div>
      <div className={styles.resultContainerRowDuplicate}>
        <span className={styles.resultLabel}>Duplicate:</span>
        <span className={styles.resultValue}>{duplicate}</span>
      </div>
      {error !== undefined && (
        <div className={styles.resultContainerRowError}>
          <span className={styles.resultLabel}>Errors:</span>
          <span className={styles.resultValue}>{error}</span>
        </div>
      )}
    </div>
  );
};

ImportResultContainer.propTypes = {
  result: PropTypes.shape({
    imported: PropTypes.number,
    duplicate: PropTypes.number,
    error: PropTypes.number,
  }),
};

const TweetsImportContainer = ({ onClose, open }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [username, setUsername] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [count, setCount] = useState(50);
  const [organizations, setOrganizations] = useState([]);
  const refresh = useRefresh();

  const handleClose = () => {
    handleReset();
    onClose(false);
  };

  const handleReset = () => {
    setUsername(null);
    setCount(50);
    setError(null);
    setResult(null);
    setIsLoading(false);
  };

  const getOrganizations = async (accessToken) =>
    await fetch(`${API_URL}/orgs/${getSubdomain(window)}/organizations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

  const importTweets = async (username, organization, count, accessToken) =>
    fetch(
      `${API_URL}/orgs/${getSubdomain(
        window
      )}/twitter/tweets?${new URLSearchParams({
        username,
        count,
        organization,
      })}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

  const importAllTweets = async (count, accessToken) =>
    fetch(
      `${API_URL}/orgs/${getSubdomain(
        window
      )}/twitter/tweets/all?${new URLSearchParams({
        count,
      })}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

  const handleSubmit = async (type) => {
    setIsLoading(true);
    const accessToken = GetLocalStorage();
    try {
      let response;
      if (type === "all") {
        response = await importAllTweets(count, accessToken);
      } else {
        if (!username || !selectedOrganization) {
          throw Error("Please fill all fields");
        }
        response = await importTweets(
          username,
          selectedOrganization.slug,
          count,
          accessToken
        );
      }

      const data = await response.json();
      setIsLoading(false);

      if (response.status !== 200) {
        throw data;
      }

      setResult(data);
      refresh();
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const accessToken = GetLocalStorage();

      try {
        const organizationsResponse = await getOrganizations(accessToken);
        const organizations = await organizationsResponse.json();

        setOrganizations(
          organizations.map(({ name, slug }) => ({ name, slug }))
        );
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.warn(error);
      }
    }
    fetchData();
  }, []);

  return (
    <ModalContainer
      onClose={handleClose}
      title="Import Tweets"
      isOpen={open}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      onReset={handleReset}
      isFinish={result !== null}
    >
      {result ? (
        <ImportResultContainer result={result} />
      ) : (
        <form className={styles.tweetsImportForm}>
          <FormControl className={styles.formControl}>
            <TextField
              error={error != null}
              style={{ width: "100%" }}
              label="username"
              name="username"
              variant="standard"
              helperText={error}
              placeholder="username"
              onChange={(e) => setUsername(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon sx={{ width: 20 }} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl className={styles.formControl}>
            <Autocomplete
              id="orgs"
              options={organizations}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => setSelectedOrganization(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={error != null}
                  helperText={error}
                  label="Campaigns"
                  placeholder="Campaigns"
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <BusinessIcon sx={{ width: 20 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl className={styles.formControl}>
            <Slider
              aria-label="Count"
              defaultValue={50}
              name="count"
              valueLabelDisplay="auto"
              step={5}
              marks
              min={5}
              max={100}
              onChange={(e) => setCount(e.target.value)}
            />
          </FormControl>
        </form>
      )}
    </ModalContainer>
  );
};

TweetsImportContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default TweetsImportContainer;
