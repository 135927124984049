import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import styles from "./HomePage.module.css";
import Header from "../../UI/Header/Header";
import Footer from "../../UI/Footer/Footer";
import MainContainer from "../../UI/HomePageComponents/MainContainer/MainContainer";
import StatInfoContainer from "../../UI/HomePageComponents/StatInfoContainer/StatInfoContainer";
import IssuesInfoContainer from "../../UI/HomePageComponents/IssuesInfoContainer/IssuesInfoContainer";
import QuestionsContainer from "../../UI/HomePageComponents/QuestionsContainer/QuestionsContainer";
import MassMediaContainer from "../../UI/HomePageComponents/MassMediaContainer/MassMediaContainer";
import JoinTheChoirContainer from "../../UI/HomePageComponents/JoinTheChoirContainer/JoinTheChoirContainer";
import { getSubdomain } from "../../../utils/GetSubdomain";
import { SetLocalStorage } from "../../../utils/StorageHandler";

const API_URL = process.env.REACT_APP_API_URL;

const HomePage = ({ organization }) => {
  const { loginWithPopup, getAccessTokenSilently, isAuthenticated, isLoading } =
    useAuth0();
  const [stats, setStats] = useState({
    actionsCount: 0,
    organizationsCount: 0,
  });

  const navigate = useNavigate();

  const initializeRequest = async (accessToken) => {
    try {
      await fetch(`${API_URL}/orgs/${getSubdomain(window)}/self/initialize`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  };

  const statsInfoRequest = () =>
    fetch(`${API_URL}/main/stats`, {
      method: "GET",
    });

  const signupHandler = async () => {
    try {
      if (!isAuthenticated) {
        await loginWithPopup({
          screen_hint: "signup",
          mode: "signUp",
        });
        const response = await getAccessTokenSilently();
        initializeRequest(response);
        SetLocalStorage(response);
      }
      navigate("/main");
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const statsResponse = await statsInfoRequest();
        const stats = await statsResponse.json();
        setStats(stats);
      } catch (error) {
        console.warn(error);
      }
    }

    if (!isLoading) {
      fetchData();
    }
  }, [isLoading]);

  return (
    <>
      <div className={styles.homePage}>
        <Header />
        <MainContainer
          signupHandler={signupHandler}
          isAuthenticated={isAuthenticated}
          organizationName={organization}
        />
        <StatInfoContainer stats={stats} />
        <IssuesInfoContainer />
        <QuestionsContainer />
        <MassMediaContainer />
        <JoinTheChoirContainer signupHandler={signupHandler} />
        <Footer />
      </div>
    </>
  );
};

HomePage.propTypes = {
  organization: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default HomePage;
