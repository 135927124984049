import * as React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";

function RoleList() {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="userId" />
        <TextField source="roleSlug" />
        <TextField source="organizationSlug" />
        <TextField source="createdBy" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
}

export default RoleList;
