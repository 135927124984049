import React from "react";
import PropTypes from "prop-types";

import HomeButton from "../../HomeButton/HomeButton";
import styles from "./JoinTheChoirContainer.module.css";

const JoinTheChoirContainer = ({ signupHandler }) => {
  return (
    <div className={styles.joinTheChoirContainer}>
      <h1 className={styles.headerText}>JOIN THE CHOIR</h1>
      <div className={styles.buttonContainer}>
        <HomeButton onClick={signupHandler}>Sign Up</HomeButton>
      </div>
    </div>
  );
};

JoinTheChoirContainer.propTypes = {
  signupHandler: PropTypes.func,
};

export default JoinTheChoirContainer;
