import React from "react";
import { Admin, Resource, fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

import ActionList from "../../UI/ActionItem/actionList";
import ActionEdit from "../../UI/ActionItem/actionEdit";
import ActionCreate from "../../UI/ActionItem/actionCreate";

import OrganizationList from "../../UI/OrganizationItem/organizationList";
import OrganizationEdit from "../../UI/OrganizationItem/organizationEdit";
import OrganizationCreate from "../../UI/OrganizationItem/organizationCreate";

import RoleList from "../../UI/RoleItem/roleList";
import RoleEdit from "../../UI/RoleItem/roleEdit";
import RoleCreate from "../../UI/RoleItem/roleCreate";

import { getSubdomain } from "../../../utils/GetSubdomain";
import { GetLocalStorage } from "../../../utils/StorageHandler";

const API_URL = process.env.REACT_APP_API_URL;

const httpClient = (url, options = {}) => {
  const accessToken = GetLocalStorage();
  if (!options.headers) {
    options.headers = new Headers({ Authorization: `Bearer ${accessToken}` });
  } else {
    options.headers.set("Authorization", `Bearer ${accessToken}`);
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
  `${API_URL}/orgs/${getSubdomain(window)}`,
  httpClient
);

const AdminPage = () => {
  return (
    <Admin basename="/admin" dataProvider={dataProvider}>
      <Resource
        name="actions"
        list={ActionList}
        edit={ActionEdit}
        create={ActionCreate}
      />
      <Resource
        icon={CorporateFareIcon}
        name="organizations"
        list={OrganizationList}
        edit={OrganizationEdit}
        create={OrganizationCreate}
      />
      <Resource
        icon={WorkspacePremiumIcon}
        name="users/roles"
        list={RoleList}
        edit={RoleEdit}
        create={RoleCreate}
        options={{ label: "Access Grant" }}
      />
    </Admin>
  );
};

export default AdminPage;
